import React from 'react'
import { IconDelete, IconDownload, IconEdit, IconPlus } from '../../../../../../shared/icon/icon'
import { Menu, MenuDelete, MenuItem, MenuLink } from '../../../../../../shared/menu/menu'
import { BreadcrumbView } from '../../../../../../context/breadcrumb.context'
import { Form, FormNumber, FormSubmit, FormText } from '../../../../../../shared/form/form'
import { FormRawJson } from '../../../../../../shared/form/form-control/form-raw-json'
import { CardFullHeightScrollY } from '../../../../../../shared/card/card-full-height-scroll-y'
import { FileUpload } from '../../../../../../shared/legacy/storage/file-upload'
import { Card } from '../../../../../../shared/card/card'
import { useFileDownloadContent } from '../../../../../../shared/legacy/storage/hooks'
import { useApiFormFileContent } from '../../../../../../api-new/state/storage'

export const NodeAssetEdit = ({ parentUrl, assetID, apiForm }) => {
  const handleMatch = () => apiForm.get(assetID)

  const handleSubmit = async data => await apiForm.put(data.id, data)

  const HeaderMenu = () =>
    <Menu>
      <MenuLink Icon={IconPlus} text="new asset" to={`${parentUrl}/asset-add`} />
      <MenuLink Icon={IconDelete} text="delete asset" to={`${parentUrl}/asset-delete/${assetID}`} />
    </Menu>

  return (
    <React.Fragment>
      <BreadcrumbView Icon={IconEdit} text="edit" onMatch={handleMatch}>
        <CardFullHeightScrollY title={apiForm.form.name} HeaderMenu={HeaderMenu}>
          <Form state={apiForm.form} onSubmit={handleSubmit}>
            <FormText label="name" name="name" required autoFocus />
            <FormText label="key" name="key" readOnly />
            <FormText label="version" name="version" readOnly />
            <FormNumber label="size" name="length" />
            <FormRawJson label="metadata" name="metaData" />
            <FormSubmit text="update" />
          </Form>

          <File apiForm={apiForm} />
        </CardFullHeightScrollY>
      </BreadcrumbView>
    </React.Fragment>
  )
}

const File = ({ apiForm }) => {
  const apiFormFileContent = useApiFormFileContent()
  const fileDownloadContent = useFileDownloadContent()

  const handleComplete = async file => {
    let asset = apiForm.form
    asset.name = file.name
    asset.length = file.size
    await apiForm.put(asset.id, asset)
  }

  const handleDownload = () => {
    fileDownloadContent(apiForm.form.name, apiForm.form.key)
  }

  const handleDelete = async () => {
    await apiFormFileContent.delete(apiForm.form.key)

    let asset = apiForm.form
    asset.length = 0
    await apiForm.put(asset.id, asset)
  }

  const HeaderMenu = () =>
    <Menu>
      <MenuItem text="download" Icon={IconDownload} onClick={handleDownload} show />
      <MenuDelete onClick={handleDelete} show />
    </Menu>

  return (
    <React.Fragment>
      <Card title="file" HeaderMenu={HeaderMenu}>
        <FileUpload storageKey={apiForm.form.key} onComplete={handleComplete} />
      </Card>
    </React.Fragment>
  )
}
